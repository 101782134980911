// For display settings > font size controls.
// 1.125-major second - https://type-scale.com/
.fs-dn-3 {
	font-size: calc($font-size-root - 0.2vw);
}
.fs-dn-2 {
	font-size: calc($font-size-root - 0.15vw);
}
.fs-dn-1 {
	font-size: calc($font-size-root - 0.07vw);
}
.fs-up-1 {
	font-size: calc($font-size-root + 0.07vw);
}
.fs-up-2 {
	font-size: calc($font-size-root + 0.15vw);
}
.fs-up-3 {
	font-size: calc($font-size-root + 0.2vw);
}

.fs-inherit {
	font-size: inherit !important;
}

.container-reading {
	width: min(70ch, 100%); // 100 width of container and max character 70
	font-size: clamp(1rem, 3vw, 1.15rem);
}

.cursor-pointer {
	cursor: pointer;
}
.small-font {
	font-size: $small-font-size;
}
.smaller-font {
	font-size: $small-font-size * .88;
}
sup {
  	vertical-align: super;
  	font-size: .5em;
	top:inherit;
}
.small-caps {
	font-variant: small-caps;
}

li.no-bullet {
	list-style: none;
}
h1, .h1 {
	font-weight: 600;
}
