@import "@mksap/styles/common_helpers";


@mixin webLoginFormGradient {
	background: rgb(2,0,36);
	// TODO: this gradient will have to get adjusted because the 100% value is offscreen in the design
	//background: linear-gradient(-145deg, rgba(2,0,36,1) 0%, rgb(140, 10, 195) 50%, rgba(#083497,1) 100%);
	background-image: linear-gradient(55deg,  rgba(2, 48, 91, 0.7), rgba(126, 63, 146, 0.5), rgba(140, 10, 195, .5), rgba(1, 88, 171, 0.7));
}

.web-login-form {
	@include webLoginFormGradient();//background-color: $white;
	min-height: 100vh;


	.carousel-item img {
		border-radius: $border-radius;
		border: 9px solid rgba($white, 0.4);
	}

	//mobile only
	@include media-breakpoint-down(md) {

		.login-card {
			background-color: $white;
		}
		.coming-soon {
			padding:1rem 1rem 0;
		}
		.col--slider {
			//@include webLoginFormGradient();
			border-radius: $border-radius;
		}
	}

	// desktop only
	@include media-breakpoint-up(md) {
		@include webLoginFormGradient();
		position: absolute;

		.container {
			position: absolute;
			transform: translate(-50%, -50%);
		}
	}

}
