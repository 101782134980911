@import "@mksap/styles/_common_helpers";
/* ==========================================================================
   loader styles
   ========================================================================== */

.loading {
	text-align: center;
	position: absolute;
  	top: 50%;
  	left: 50%;
  	transform: translate(-50%, -50%);
	h4 {
		margin-bottom: 5px;
		font-size: 1em;
	}
	span {
		display: inline-block;
		vertical-align: middle;
		width: 1.7em;
		height: 0.3em;
		margin: 0.25em;
		background: #006549;
		border-radius: 0;
		animation: loading 1s infinite alternate;
	}
	&.lg {
		background-color: rgba(255, 255, 255, 0.5);
		margin: 0 auto;
		padding: 20px;
		width: 400px;
		> img {
			max-width: 350px;
			margin: 1em auto;
		}
	}
	&.sm {
		background-color: transparent;
		margin-top: 20px;
		h4 {
			font-size: 1.5em;
		}
	}
}
.loading span:nth-of-type(2) {
	background: $acp-green;
	animation-delay: 0.2s;
}
.loading span:nth-of-type(3) {
	background: $acp-green-bright;
	animation-delay: 0.4s;
}
.loading span:nth-of-type(4) {
	background: $acp-yellow;
	animation-delay: 0.6s;
}
.loading span:nth-of-type(5) {
	background: $orange;
	animation-delay: 0.8s;
}
.loading span:nth-of-type(6) {
	background: $acp-red;
	animation-delay: 1s;
}
.loading span:nth-of-type(7) {
	background: $acp-blue-dark;
	animation-delay: 1.2s;
}

@keyframes loading {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
