@import "@mksap/styles/_common_helpers";

// Make a button look and behave like a inline link
.btn-inline-link, a, .btn-link {
	// Copied from .btn-link in bootstrap _buttons.scss
	//--#{$prefix}btn-font-weight: #{$font-weight-normal};
	--#{$prefix}btn-color: #{$btn-link-color};
	--#{$prefix}btn-bg: transparent;
	--#{$prefix}btn-border-color: transparent;
	--#{$prefix}btn-hover-color: #{$btn-link-hover-color};
	--#{$prefix}btn-hover-border-color: transparent;
	--#{$prefix}btn-active-color: #{$btn-link-hover-color};
	--#{$prefix}btn-active-border-color: transparent;
	--#{$prefix}btn-disabled-color: #{$btn-link-disabled-color};
	--#{$prefix}btn-disabled-border-color: transparent;
	--#{$prefix}btn-box-shadow: 0 0 0 #000; // Can't use `none` as keyword negates all values when used with multiple shadows
	--#{$prefix}btn-focus-shadow-rgb: #{to-rgb(mix(color-contrast($link-color), $link-color, 15%))};

	text-decoration: $link-decoration;
	gap: $icon-link-gap;
   	align-items: center;
   	text-decoration-color: rgba(var(--#{$prefix}link-color-rgb), var(--#{$prefix}link-opacity, .5));
   	text-underline-offset: .15em;

	// Customization...
	display: inline;
	padding: 0;
	border: 0;
	vertical-align: inherit;
	font-size: inherit;
	line-height: inherit;
	font-family: inherit;
	user-select: auto;

	@if $enable-gradients {
		background-image: none;
	}

	&:hover,
	&:focus-visible {
	  text-decoration: $link-hover-decoration;
	  text-decoration-color: rgba(var(--#{$prefix}link-color-rgb), var(--#{$prefix}link-opacity, .75));
	}
	@include in-color-mode(dark) {
		--#{$prefix}btn-color: #{$link-color-dark};
	}
}
.btn.rounded-pill {
	display:flex;
	flex-flow:row nowrap;
	align-items: center;
	justify-content: center;
}
.btn {
	border-radius: $border-radius-pill;//$btn-border-radius-lg;
	display:inline-flex;
	align-items:center;
	&.icon-btn {
		display:inline-flex;
		flex-flow:row nowrap;
		align-items:center;
		justify-content: center;
		padding: $btn-padding-x;
		border-radius:$border-radius-pill;
		&.btn-sm {
			padding:$btn-padding-x-sm;
		}
		&.btn-lg {
			padding:$btn-padding-x-lg;
		}
	}
	.badge {
		top:0;
		margin-left:.5rem;
	}
  }
  .card .icon-btn.btn {
	box-shadow:none;
  }
  .d-grid .btn {
	justify-content: center;
  }
  .btn:not(:disabled):not(.disabled):focus,
  .btn:not(:disabled):not(.disabled).focus,
  .btn:not(:disabled):not(.disabled):visited:focus,
  .btn:not(:disabled):not(.disabled):visited.focus {
	box-shadow: $focus-ring-box-shadow;
  }
// TODO: Delete once Kate has moved any styles from here into .btn-inline-link
// .btn-inline {
// 	display: inline-flex;
//   	gap: $icon-link-gap;
//   	align-items: center;
//   	text-decoration-color: rgba(var(--#{$prefix}link-color-rgb), var(--#{$prefix}link-opacity, .25));
//   	text-underline-offset: $icon-link-underline-offset;
//   	backface-visibility: hidden;
// 	padding: 0;
// 	border: 0;
// 	font-size: inherit;
// 	vertical-align: baseline;
// 	> .svg-icon {
// 		flex-shrink: 0;
// 		width: $icon-link-icon-size;
// 		height: $icon-link-icon-size;
// 		fill: currentcolor;
// 		//@include transition($icon-link-icon-transition);
// 	}
// 	&:hover,
// 	&:focus-visible {
// 		text-decoration-color: rgba(var(--#{$prefix}link-color-rgb), var(--#{$prefix}link-opacity, .75));
// 	}
// }
.card-link {
	text-decoration:none;
}

.icon-btn.btn-light.btn-none {
		--bs-btn-color: var(--bs-light);
  		--bs-btn-bg: var(--bs-light);
  		--bs-btn-border-color: var(--bs-light);
  		--bs-btn-hover-color: var(--bs-white);
  		--bs-btn-hover-bg: #{tint-color($tertiary, 85%)};
  		--bs-btn-hover-border-color: #{tint-color($tertiary, 70%)};
  		--bs-btn-focus-shadow-rgb: var(--bs-light-rgb);
  		--bs-btn-active-color: var(--bs-btn-hover-color);
  		--bs-btn-active-bg: #{tint-color($tertiary, 70%)};
  		--bs-btn-active-border-color: #{tint-color($tertiary, 70%)};
	@include in-color-mode(dark) {
  		--bs-btn-color: var(--bs-dark);
  		--bs-btn-bg: var(--bs-dark);
  		--bs-btn-border-color: var(--bs-dark);
  		--bs-btn-hover-color: var(--bs-secondary);
  		--bs-btn-hover-bg: #{shade-color($secondary, 10%)};
  		--bs-btn-hover-border-color: #{shade-color($primary, 10%)};
  		--bs-btn-focus-shadow-rgb: var(--bs-dark-rgb);
  		--bs-btn-active-color: var(--bs-btn-hover-color);
  		--bs-btn-active-bg: #{shade-color($dark, 20%)};
  		--bs-btn-active-border-color: #{shade-color($dark, 20%)};
	}
}
