// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "../../../node_modules/bootstrap/scss/functions";
// 2. Include any default variable overrides here
@import "./custom-bootstrap-variables";
// 3. Include remainder of required Bootstrap stylesheets
@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/variables-dark";
@import "../../../node_modules/bootstrap/scss/mixins";

@mixin in-color-mode($mode) {
    $selector: &;

    @at-root {
        @include color-mode(dark) {
            @if $selector {
                #{$selector} {
                    @content;
                }
            }
            @else {
                @content;
            }

        }
    }
}

@import "./mksap_variables";
