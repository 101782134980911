@import "@mksap/styles/common_helpers";

.new-carousel {

	&__image-container {
		border-radius: $border-radius;
		border: 9px solid rgba($white, 0.4);
	}
	&__image-container-inner {
		transition: left 400ms ease-in-out;
	}
	&__image-item {
		max-width: 100%;
	}


	&__nav-arrow {
		position: absolute;
		font-size: 2em;
		line-height: 2em;
		top: 50%;
		transform: translateY(-50%);
		z-index: 100;
		color: $white;
		opacity: 0.8;
		transition: opacity 150ms, background-color 150ms;
		padding: 5px;
		background-color: transparent;
		border: 0;

		.svg-icon {
			display: block;
		}

		&:hover {
			opacity: 1;
			background-color: rgba($primary, 0.4);
		}

		&--previous {
			left: 10px;
		}
		&--next {
			right: 10px;
		}
	}
	&__nav-item {
		background-color: transparent;
		border: 0;
		opacity: 0.5;
		transition: opacity 150ms;

		&.current, &:hover {
			opacity: 0.9;
		}
		&:active {
			opacity: 1;
		}
	}
	&__nav-item-inner {
		width: 20px;
		height: 20px;
		border-radius: $border-radius;
	}
}


