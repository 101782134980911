@import "@mksap/styles/_common_helpers";

.svg-icon {
	display: inline-block;
	position: relative;
	//top: .125em;
	width: 1em;
	height: 1em;
	stroke-width: 0;
	stroke: currentColor;
	fill: currentColor;
	vertical-align: middle;
	transition: fill 0.3s;
	flex-shrink: 0;
	outline:0;

	> use {
		// The <use> elements in the svg icons eat up the click events in icon buttons in Edge. This seems to solve that:
		pointer-events: none;
	}
	&.small {
		width:.875rem;
		height:.875rem;
	}
	&.large {
		width:1.5rem;
		height:1.5rem;
	}
	&.svg-icon__first {
		margin-right: 0.5rem;
	}
	&.svg-icon__last {
		margin-left: 0.5rem;
	}
}

/* ==========================================
Single-colored icons can be modified like so:
.svg-name {
  font-size: 32px;
  color: red;
}
========================================== */
.svg-acpMKSAP-logo-4c {
	width:8rem;
	height:1rem;
}
.svg-acp-logo-horiz-w {
	width:17rem;
	height:2.1rem;
}
.svg-acp-logo-horizontal-4c {
	width:16rem;
	height:2rem;
}
.lock-img {
	font-size:5rem;
	line-height:normal;
}
.svg-flag-canada {
	width: 6em;
}
.svg-logo-acp-tracker {
	width: 8em;
	height:2em;
}

.svg-logo-acp-mksap19-horiz-w {
	width: 100%;
	height: 100%;
}
.svg-logo-acp-mksap-19-4c {
	width: 100%;
	height: 2rem;
}
.svg-logo-acp-mksap19-w {
	width: 8em;
}
.svg-logo-mksap-19-w {
	width: 6em;
}
.svg-logo-acp-mksap-horiz-w {
	width: 7em;
}
.svg-logo-acp-logo-stack-w {
	width: 6em;
}
.svg-logo-acp-logo-stack-4c {
	width: 8em;
	height: 3em;
}
.svg-badge-Annals {
	width: 3em;
}

.svg-badge-Hospitalist {
	width: 4em;
}

.svg-badge-Internist {
	width: 3em;
}

.svg-bubble-grid {
	stroke-width: 1px;
}

.svg-shape-bubble {
	width: 1.5em;
}

.svg-shape-bubble-empty {
	width: 1.5em;
}

.svg-user-alert {
	width: 1.232421875em;
}
.svg-niu {
	width: 1.15em;
}

@mixin icon-colors($fallback: black) {
	fill: $fallback;
	@content;
}
//only use if css is not an option, experiment with multi color icons
.svg-icon {
	&--white {
		@include icon-colors(white);
	}
	&--lightBlue {
		@include icon-colors($acp_blue_lightest);
	}
	&--gold {
		@include icon-colors($acp_gold);
	}
	&--orange {
		@include icon-colors($acp_orange);
	}
	&--red {
		@include icon-colors($acp_red);
	}
	&--green {
		@include icon-colors($acp_green_bright);
	}
}

.svg-hospitalist {
	fill: $acp_blue_light;
}
//find a better home for this
.keypoints-list {
	.svg-hvc {
		fill: $orange;
		width:.85em;
		height:.85em;
		vertical-align: baseline;
	}
}
.svg-warning-w {
	fill: white;
}

svg.defs-only {
	display: block;
	position: absolute;
	height: 0;
	width: 0;
	margin: 0;
	padding: 0;
	border: none;
	overflow: hidden;
}
.empty-svg-icon.svg-icon {
	fill: $gray-400 !important;
}
