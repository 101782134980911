//
// Custom Bootstrap
//

// Include the common variables, mixins, etc. needed for bootstrap.
@import "./common_helpers";

// 4. Include any optional Bootstrap components as you like
// Why start at step 4? Because steps 1-3 are handled in the common helpers file.
@import "../../../node_modules/bootstrap/scss/helpers";
@import "../../../node_modules/bootstrap/scss/maps";
@import "../../../node_modules/bootstrap/scss/utilities";
@import "../../../node_modules/bootstrap/scss/root";
@import "../../../node_modules/bootstrap/scss/reboot";
@import "../../../node_modules/bootstrap/scss/type";
@import "../../../node_modules/bootstrap/scss/images";
@import "../../../node_modules/bootstrap/scss/containers";
@import "../../../node_modules/bootstrap/scss/grid";
@import "../../../node_modules/bootstrap/scss/tables";
@import "../../../node_modules/bootstrap/scss/forms";
@import "../../../node_modules/bootstrap/scss/transitions";
@import "../../../node_modules/bootstrap/scss/dropdown";
@import "../../../node_modules/bootstrap/scss/buttons";
@import "../../../node_modules/bootstrap/scss/button-group";
@import "../../../node_modules/bootstrap/scss/navbar";
@import "../../../node_modules/bootstrap/scss/accordion";
@import "../../../node_modules/bootstrap/scss/nav";
@import "../../../node_modules/bootstrap/scss/close";
@import "../../../node_modules/bootstrap/scss/card";
@import "../../../node_modules/bootstrap/scss/breadcrumb";
@import "../../../node_modules/bootstrap/scss/badge";
@import "../../../node_modules/bootstrap/scss/alert";
@import "../../../node_modules/bootstrap/scss/list-group";
@import "../../../node_modules/bootstrap/scss/close";
@import "../../../node_modules/bootstrap/scss/modal";
@import "../../../node_modules/bootstrap/scss/offcanvas";
@import "../../../node_modules/bootstrap/scss/progress";
@import "../../../node_modules/bootstrap/scss/pagination";
@import "../../../node_modules/bootstrap/scss/toasts";
@import "../../../node_modules/bootstrap/scss/tooltip";
@import "../../../node_modules/bootstrap/scss/popover";
@import "../../../node_modules/bootstrap/scss/utilities/api";
@import "../../../node_modules/bootstrap/scss/carousel";


@include color-mode(dark) {
    // All-caps `RGBA()` function used because of this Sass bug: https://github.com/sass/node-sass/issues/2251
	@each $color, $value in $theme-colors-dark {
		$color-rgb: to-rgb($value);
		.bg-#{$color} {
			background-color: RGBA($color-rgb, 1 ) if($enable-important-utilities, !important, null);
		}
	}

	@each $color, $value in $theme-colors-dark {
        $color-rgb: to-rgb($value);
        .text-bg-#{$color} {
            color: color-contrast($value) if($enable-important-utilities, !important, null);
            background-color: RGBA($color-rgb, 1) if($enable-important-utilities, !important, null);
        }
    }
	@each $color, $value in $theme-colors-dark {
        $color-rgb: to-rgb($value);
        .bg-#{$color}-subtle {
            color: color-contrast($value) if($enable-important-utilities, !important, null);
            background-color: RGBA($color-rgb, .15) if($enable-important-utilities, !important, null);
        }
    }

	@each $color, $value in $theme-colors-dark {
		$color-rgb: to-rgb($value);
		.text-#{$color} {
			color: RGBA($color-rgb, 1) if($enable-important-utilities, !important, null);
		}
	}
	@each $color, $value in $theme-colors-dark {
		$color-rgb: to-rgb($value);
		.text-#{$color}-emphasis {
			color: RGBA($color-rgb, 1) if($enable-important-utilities, !important, null);
		}
	}
	@each $color, $value in $theme-colors-dark {
		$color-rgb: to-rgb($value);
		.border-#{$color} {
			border-color: RGBA($color-rgb, 1) if($enable-important-utilities, !important, null);
		}
	}

    @each $color, $value in $theme-colors-dark {
        .btn-#{$color} {
            @include button-variant($value, $value);
        }
    }

    @each $color, $value in $theme-colors-dark {
		$color-rgb: to-rgb($value);
      	.btn-outline-#{$color} {
			border-color: RGBA($color-rgb, 1) if($enable-important-utilities, !important, null);
			color: RGBA($color-rgb, 1) if($enable-important-utilities, !important, null);
			background-color: rgba(255, 255, 255, .001);
			@include button-variant($value, $value);
			&:hover, &:active {
				background-color:rgba(255, 255, 255, .1);
			}
      	}
    }
}

.bg-tertiary-subtle {
	background-color:var(--bs-info-bg-subtle) !important;
}
.text-muted-emphasis {
	color:var(--bs-muted-text-emphasis) !important;
}
.bg-muted-subtle {
	background-color:var(--bs-muted-bg-subtle) !important;
}
.text-correct-emphasis {
	color:var(--bs-success-text-emphasis) !important;
}

.text-incorrect-emphasis {
	color:var(--bs-danger-text-emphasis) !important;
}
.text-unsure-emphasis {
	color:var(--bs-warning-text-emphasis) !important;
}

.card, .modal-content, .modal-footer, .dropdown-menu {
	background-color: $card-bg;
	@include in-color-mode(dark) {
		background-color:$gray-900; //#0a0d10;
		//border-color:$light-border-subtle-dark;
	}
}

.list-group-item, .accordion-item, .accordion-button, .accordion-body {
	@include in-color-mode(dark) {
		background-color:$gray-900;// #0a0d10;
		border-color:$gray-800;
	}
}
.list-group-item.list-group-item-action:hover, .list-group-item.list-group-item-action:focus {
	@include in-color-mode(dark) {
		background-color:rgba($secondary, .2);
	}
}
.list-group-item.active {
	@include in-color-mode(dark) {
		color:$link-color-dark;
	}
}

.date-header {
	background-color: $gray-400;
	@include in-color-mode(dark) {
		background-color: $gray-800;
	}
}
.form-check-input {
	background-color:$white;
	border-color:$gray-500;
	@include in-color-mode(dark) {
		background-color:$gray-400;
	}
}
.tag-types.bg-primary-subtle{
	@include in-color-mode(dark) {
		//background-color:$gray-500;

	}
}
.navbar.bg-secondary,
.main-nav.nav{
	@include in-color-mode(dark) {
		//background-color:rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
	}
}
.soft-bg-gradiant.bg-info-subtle {
	@include in-color-mode(dark) {
		color:$gray-100 !important;
	}
}
