@import "@mksap/styles/_common_helpers";

.button-spinner {
	animation: button-spinner-rotate 2s linear infinite;
	transform-origin: center center;

	& .button-spinner__path {
		stroke-dasharray: 1, 200;
		stroke-dashoffset: 0;
		stroke: $white;
		animation: button-spinner-dash 1.5s ease-in-out infinite;
		stroke-linecap: round;
	}
}

@keyframes button-spinner-rotate {
	100% {
		transform: rotate(360deg);
	}
}

@keyframes button-spinner-dash {
	10% {
		stroke-dasharray: 1, 200;
		stroke-dashoffset: 0;
	}
	50% {
		stroke-dasharray: 89, 200;
		stroke-dashoffset: -35px;
	}
	100% {
		stroke-dasharray: 89, 200;
		stroke-dashoffset: -124px;
	}
}
